
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

import { Auth } from "@/api";

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class ResetPasswordPage extends Vue {
  password = "";
  confirmPassword = "";

  async changePassword(): Promise<void> {
    try {
      const email = this.$route.query.email.toString().replace(/ /g, "+");
      const { message } = await Auth.changePassword(
        this.$route.query.token.toString(),
        email,
        this.password,
        this.confirmPassword
      );
      await this.$success(message);
      await this.$router.push({ name: "Login" });
    } catch (e) {
      const { errors } = e as any;
      (this.$refs.resetPasswordForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(errors);
      await this.$error(errors);
    }
  }
}
